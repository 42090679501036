html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: overlay;
}

.whitePlaceholder::placeholder {
  color: white;
}

.whitePlaceholder:focus {
  outline: none;
}
